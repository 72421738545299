import makeStyles from "@mui/styles/makeStyles";
import Service2 from "../../assets/images/svgs/service2.svg";
import colors from "../../themes/colors";
import { fontType } from "../../themes/fonts";

const style = makeStyles(() => ({
  title: {
    color: colors.textPrimary,
    textTransform: "uppercase",
    fontFamily: fontType.RobotoBold,
    textAlign: "center",
  },
  app: {
    background: colors.appBg,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  mobileApp: {
    color: colors.secondary,
    textTransform: "uppercase",
  },
  webApp: {
    color: colors.primary,
    textTransform: "uppercase",
  },
  mobileContent: {
    color: colors.secondary,
    textAlign: "center",
  },
  webContent: {
    color: colors.primary,
    textAlign: "center",
  },
  paperContainer: {
    backgroundImage: `url(${Service2})`,
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
  },
}));

export default style;
