import * as React from "react";
import { Grid, Typography } from "@mui/material";
import style from "./style";
import Instagram from "../../assets/images/svgs/instagram.svg";
import Facebook from "../../assets/images/svgs/facebook.svg";
import Twitter from "../../assets/images/svgs/twitter.svg";
import Gmail from "../../assets/images/svgs/gmail.svg";
import { Container } from "@mui/system";

function Social() {
  const classes = style();
  return (
    <Grid pt={8} pb={8} className={classes.socialContainer}>
      <Container>
        <Typography variant="body2" className={classes.subtitle}>
          Ready to collaborate?
        </Typography>
        <Typography variant="h3" className={classes.title}>
          Social
        </Typography>
        <Grid container mt={10} mb={10}>
          <Grid item md={3} className={classes.socialMedia} direction="column">
            <img src={Instagram} />
            <Typography mt={3} variant="body2" className={classes.subtitle}>
              Instagram
            </Typography>
          </Grid>
          <Grid item md={3} className={classes.socialMedia} direction="column">
            <img src={Facebook} />
            <Typography mt={3} variant="body2" className={classes.subtitle}>
              Facebook
            </Typography>
          </Grid>
          <Grid item md={3} className={classes.socialMedia} direction="column">
            <img src={Twitter} />
            <Typography mt={4} variant="body2" className={classes.subtitle}>
              Twitter
            </Typography>
          </Grid>
          <Grid item md={3} className={classes.socialMedia} direction="column">
            <img src={Gmail} />
            <Typography mt={5} variant="body2" className={classes.subtitle}>
              Gmail
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Grid>
  );
}

export default Social;
