import makeStyles from "@mui/styles/makeStyles";
import Blogs from "../../assets/images/svgs/blog_background.svg";
import colors from "../../themes/colors";
import { fontType } from "../../themes/fonts";

const style = makeStyles(() => ({
  paperContainer: {
    backgroundImage: `url(${Blogs})`,
    padding: "10px 0px",
  },
  title: {
    color: colors.white,
    textTransform: "uppercase",
    fontFamily: fontType.RobotoBold,
    textAlign: "center",
  },
  bloggerName: {
    color: colors.textPrimary,
    textTransform: "uppercase",
  },
}));

export default style;
