import makeStyles from "@mui/styles/makeStyles";
import colors from "../../themes/colors";
import { fontType } from "../../themes/fonts";

const style = makeStyles(() => ({
  title: {
    color: colors.textPrimary,
    textTransform: "uppercase",
    fontFamily: fontType.RobotoBold,
    textAlign: "center",
  },
  webApp: {
    color: colors.primary,
    textTransform: "uppercase",
  },
}));

export default style;
