import makeStyles from "@mui/styles/makeStyles";
import Slider1 from "../../assets/images/svgs/slider2.svg";
import colors from "../../themes/colors";
import { fontType } from "../../themes/fonts";

const style = makeStyles(() => ({
  paperContainer: {
    backgroundImage: `url(${Slider1})`,
    height: "900px",
  },
  title: {
    color: colors.white,
    textTransform: "uppercase",
    fontFamily: fontType.RobotoBold,
  },
  subTitle: {
    color: colors.secondary,
    textTransform: "uppercase",
  },
  checkout: {
    color: colors.white,
  },
}));

export default style;
