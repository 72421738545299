import { createTheme } from "@mui/material/styles";

const theme = createTheme({
  palette: {
    primary: {
      main: "#25272B",
    },
    secondary: {
      main: "#E6DE77",
    },
    tertiary: {
      main: "#F2F4ED",
    },
    black: {
      main: "#000000",
    },
    white: {
      main: "#FFFFFFF",
    },
  },
});

export default theme;
