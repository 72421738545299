import * as React from "react";
import { Container } from "@mui/system";
import { Grid, Typography } from "@mui/material";
import Logo from "../../assets/images/svgs/logo.svg";
import PhoneEnabledIcon from "@mui/icons-material/PhoneEnabled";
import EmailIcon from "@mui/icons-material/Email";
import style from "./style";

function Footer() {
  const classes = style();
  return (
    <Grid container pt={6} pb={15} className={classes.footerBg}>
      <Container>
        <Grid container sm={12} md={12}>
          <Grid container item sm={12} md={8}>
            <Grid item>
              <Typography variant="body1" className={classes.title}>
                Home
              </Typography>
            </Grid>
            <Grid item ml={5}>
              <Typography variant="body1" className={classes.title}>
                About Us
              </Typography>
            </Grid>
            <Grid item ml={5}>
              <Typography variant="body1" className={classes.title}>
                Contact Us
              </Typography>
            </Grid>
            <Grid item ml={5}>
              <Typography variant="body1" className={classes.title}>
                Services
              </Typography>
            </Grid>
            <Grid item ml={5}>
              <Typography variant="body1" className={classes.title}>
                Blog
              </Typography>
            </Grid>
            <Grid container sm={12} md={8} mt={5}>
              <Grid container item md={6}>
                <Grid item>
                  <PhoneEnabledIcon color="tertiary" />
                </Grid>
                <Grid item ml={1}>
                  <Typography variant="body1" className={classes.subTitle}>
                    +91 7742378593
                  </Typography>
                </Grid>
              </Grid>

              <Grid container item md={6}>
                <Grid item>
                  <EmailIcon color="tertiary" />
                </Grid>
                <Grid item ml={1}>
                  <Typography variant="body1" className={classes.subTitle}>
                    triopsinfotech@gmail.com
                  </Typography>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item sm={12} md={4}>
            <img className={classes.logoStyle} src={Logo} />
          </Grid>
        </Grid>
      </Container>
    </Grid>
  );
}

export default Footer;
