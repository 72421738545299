const fontWeight = {
  full: "900",
  semi: "600",
  low: "400",
  bold: "bold",
  normal: "normal",
};

const fontType = {
  RobotoBold: "Roboto-Bold",
  RobotoMedium: "Roboto-Medium",
  RobotoRegular: "Roboto-Regular",
  RobotoBlack: "Roboto-Black",
};

const fontSize = {
  font10: "10px",
  font12: "12px",
  font14: "14px",
  font16: "16px",
  font18: "18px",
  font20: "20px",
  font24: "24px",
  font26: "26px",
  font28: "28px",
  font30: "30px",
  font32: "32px",
  font34: "34px",
  font36: "36px",
  font40: "40px",
};

export { fontWeight, fontType, fontSize };
