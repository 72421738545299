import * as React from "react";
import Footer from "../components/Footer/Footer";
import Carousel from "../components/Carousel/Carousel";
import Services from "../components/Services/Services";
import About from "../components/AboutCompany/About";
import Blogs from "../components/Blog/Blog";
import Social from "../components/Social/Social";
import ContactUs from "../components/Contact/ContactUs";

function Home() {
  return (
    <>
      <Carousel />
      <Services />
      <About />
      <Blogs />
      <Social />
      <ContactUs />
      <Footer />
    </>
  );
}

export default Home;
