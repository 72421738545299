import * as React from "react";
import {
  Button,
  Container,
  Grid,
  Paper,
  Typography,
  Stack,
} from "@mui/material";
import style from "./style";
import Header from "../Header/Header";

function Carousel() {
  const classes = style();
  return (
    <Grid>
      <Paper className={classes.paperContainer}>
        <Header />
        <Container>
          <Grid mt={30} ml={80}>
            <Typography variant="h3" className={classes.title}>
              We are
            </Typography>
            <Grid container mt={2}>
              <Typography variant="h2" className={classes.subTitle}>
                Hiring
              </Typography>
              <Typography ml={1} variant="h2" className={classes.subTitle}>
                People
              </Typography>

              <Typography mt={8} variant="h5" className={classes.checkout}>
                Check out our works!
              </Typography>
            </Grid>

            <Stack spacing={2} direction="row" mt={8}>
              <Button size="large" color="secondary" variant="contained">
                Our Work
              </Button>
              <Button size="large" color="tertiary" variant="outlined">
                Apply Now
              </Button>
            </Stack>
          </Grid>
        </Container>
      </Paper>
    </Grid>
  );
}

export default Carousel;
