import makeStyles from "@mui/styles/makeStyles";
import colors from "../../themes/colors";
import { fontType } from "../../themes/fonts";

const style = makeStyles(() => ({
  socialContainer: {
    background: colors.tertiary,
  },
  title: {
    color: colors.primary,
    textTransform: "uppercase",
    fontFamily: fontType.RobotoBold,
    textAlign: "center",
  },
  subtitle: {
    color: colors.primary,
    textTransform: "uppercase",
    fontFamily: fontType.RobotoBold,
    textAlign: "center",
  },
  socialMedia: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

export default style;
