import * as React from "react";
import { Grid, Typography } from "@mui/material";
import style from "./style";
import { Container } from "@mui/system";
import PlaceIcon from "@mui/icons-material/Place";
import EmailIcon from "@mui/icons-material/Email";
import PhoneAndroidIcon from "@mui/icons-material/PhoneAndroid";
import ContactForm from "./ContactForm";

function ContactUs() {
  const classes = style();
  return (
    <Container>
      <Grid container pt={10} pb={20}>
        <Grid md={4}>
          <Typography variant="h3" className={classes.title}>
            Get In Touch
          </Typography>
          <Grid container mt={5}>
            <Grid item mt={1} pr={1} borderRight={1}>
              <PlaceIcon />
            </Grid>
            <Grid item pl={2}>
              <Typography variant="body1" className={classes.subtitle}>
                our office
              </Typography>
              <Typography variant="body2" className={classes.content}>
                Bhilwara, Rajasthan, India
              </Typography>
            </Grid>
          </Grid>
          <Grid container mt={5}>
            <Grid item mt={1} pr={1} borderRight={1}>
              <EmailIcon />
            </Grid>
            <Grid item pl={2}>
              <Typography variant="body1" className={classes.subtitle}>
                Email Address
              </Typography>
              <Typography variant="body2" className={classes.content}>
                triopsinfotech@gmail.com
              </Typography>
            </Grid>
          </Grid>
          <Grid container mt={5}>
            <Grid item mt={1} pr={1} borderRight={1}>
              <PhoneAndroidIcon />
            </Grid>
            <Grid item pl={2}>
              <Typography variant="body1" className={classes.subtitle}>
                Give us a call
              </Typography>
              <Typography variant="body2" className={classes.content}>
                +91 7742378593
              </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Grid md={8}>
          <ContactForm />
        </Grid>
      </Grid>
    </Container>
  );
}

export default ContactUs;
