import * as React from "react";
import { Container } from "@mui/system";
import { Grid, Typography } from "@mui/material";
import Logo from "../../assets/images/svgs/triops_logo.svg";
import style from "./style";

function Header() {
  const classes = style();
  return (
    <Grid container pt={2} pb={2} className={classes.headerBg}>
      <Container>
        <Grid container sm={12} md={12}>
          <Grid item sm={12} md={6}>
            <img className={classes.logoStyle} src={Logo} />
          </Grid>
          <Grid container item sm={12} md={6}>
            <Grid item mt={1}>
              <Typography
                borderBottom={1}
                variant="body2"
                className={classes.home}
              >
                Home
              </Typography>
            </Grid>
            <Grid item ml={5} mt={1}>
              <Typography variant="body2" className={classes.title}>
                About Us
              </Typography>
            </Grid>
            <Grid item ml={5} mt={1}>
              <Typography variant="body2" className={classes.title}>
                Contact Us
              </Typography>
            </Grid>
            <Grid item ml={5} mt={1}>
              <Typography variant="body2" className={classes.title}>
                Services
              </Typography>
            </Grid>
            <Grid item ml={5} mt={1}>
              <Typography variant="body2" className={classes.title}>
                Blog
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Container>
    </Grid>
  );
}

export default Header;
