import * as React from "react";
import { Button, Grid, Paper, Typography } from "@mui/material";
import style from "./style";
import Service from "../../assets/images/svgs/service.svg";
import Mobile from "../../assets/images/svgs/mobile.svg";
import Laptop from "../../assets/images/svgs/laptop.svg";

function Services() {
  const classes = style();
  return (
    <Grid>
      <Grid mt={8}>
        <Typography variant="h3" className={classes.title}>
          Services
        </Typography>

        <Grid container mt={6}>
          <Grid item md={4}>
            <img src={Service} />
          </Grid>
          <Grid item md={4} className={classes.app} flexDirection="column">
            <img src={Mobile} />
            <Typography mt={6} variant="h6" className={classes.mobileApp}>
              Mobile Apps
            </Typography>
            <Typography
              mt={3}
              mb={8}
              pl={12}
              pr={12}
              variant="body1"
              className={classes.mobileContent}
            >
              We create fully functional mobile apps with different languages
              like React Native, Flutter etc.
            </Typography>
            <Button size="large" color="secondary" variant="contained">
              Learn More
            </Button>
          </Grid>
          <Grid item md={4}>
            <Paper className={classes.paperContainer}>
              <img src={Laptop} />
              <Typography mt={6} variant="h6" className={classes.webApp}>
                Web Apps
              </Typography>
              <Typography
                mt={3}
                mb={8}
                pl={12}
                pr={12}
                variant="body1"
                className={classes.webContent}
              >
                We create fully responsive web apps with different languages
                like React JS, Angular etc.{" "}
              </Typography>
              <Button size="large" color="primary" variant="outlined">
                Learn More
              </Button>
            </Paper>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Services;
