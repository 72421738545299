import * as React from "react";
import { Button, Grid, TextField } from "@mui/material";
import style from "./style";

function ContactForm() {
  const classes = style();
  return (
    <Grid>
      <Grid>
        <TextField placeholder="Name" className={classes.inputField} />
      </Grid>
      <Grid mt={2}>
        <TextField placeholder="Email" className={classes.inputField} />
      </Grid>
      <Grid mt={2}>
        <TextField placeholder="Subject" className={classes.inputField} />
      </Grid>
      <Grid mt={2}>
        <TextField placeholder="Your Message" className={classes.inputField} />
      </Grid>
      <Grid mt={4}>
        <Button
          variant="outlined"
          color="primary"
          sx={{
            borderRadius: "50px",
            padding: "10px 25px",
            border: "1px solid #bdc0bb",
          }}
        >
          Send Message
        </Button>
      </Grid>
    </Grid>
  );
}

export default ContactForm;
