import * as React from "react";
import { Grid, Typography, Paper } from "@mui/material";
import style from "./style";
import Blog1 from "../../assets/images/svgs/blog1.svg";
import Blog2 from "../../assets/images/svgs/blog2.svg";
import Blog3 from "../../assets/images/svgs/blog3.svg";
import {
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
} from "@material-ui/core";
import { Container } from "@mui/system";
import colors from "../../themes/colors";

function Blogs() {
  const classes = style();
  return (
    <Grid>
      <Paper className={classes.paperContainer}>
        <Grid mt={4}>
          <Typography variant="h3" className={classes.title}>
            Blogs
          </Typography>
        </Grid>

        <Container>
          <Grid container mt={8} mb={10} columnSpacing={4}>
            <Grid item sm={12} md={4}>
              <Card>
                <CardActionArea>
                  <CardMedia component="img" image={Blog1} />
                  <CardContent>
                    <Typography
                      className={classes.bloggerName}
                      variant="h6"
                      pb={2}
                      pt={1}
                      borderBottom={1}
                      borderColor={colors.border}
                    >
                      Kirti Sinha
                    </Typography>
                    <Typography
                      mt={2}
                      mb={5}
                      variant="body2"
                      color="textSecondary"
                    >
                      I Like to write blogs, So I’m writing for the first time
                      Triops Infotech Company.
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>

            <Grid item sm={12} md={4}>
              <Card>
                <CardActionArea>
                  <CardMedia component="img" image={Blog2} />
                  <CardContent>
                    <Typography
                      className={classes.bloggerName}
                      variant="h6"
                      pb={2}
                      pt={1}
                      borderBottom={1}
                      borderColor={colors.border}
                    >
                      Neha Joshi
                    </Typography>
                    <Typography
                      mt={2}
                      mb={5}
                      variant="body2"
                      color="textSecondary"
                    >
                      I Like to write blogs, So I’m writing for the first time
                      Triops Infotech Company.
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>

            <Grid item sm={12} md={4}>
              <Card>
                <CardActionArea>
                  <CardMedia component="img" image={Blog3} />
                  <CardContent>
                    <Typography
                      className={classes.bloggerName}
                      variant="h6"
                      pb={2}
                      pt={1}
                      borderBottom={1}
                      borderColor={colors.border}
                    >
                      Qirat M.
                    </Typography>
                    <Typography
                      mt={3}
                      mb={4}
                      variant="body2"
                      color="textSecondary"
                    >
                      I Like to write blogs, So I’m writing for the first time
                      Triops Infotech Company.
                    </Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
            </Grid>
          </Grid>
        </Container>
      </Paper>
    </Grid>
  );
}

export default Blogs;
