import makeStyles from "@mui/styles/makeStyles";
import colors from "../../themes/colors";

const style = makeStyles(() => ({
  title: {
    color: colors.primary,
    textTransform: "capitalize",
  },
  subtitle: {
    color: colors.primary,
    textTransform: "uppercase",
  },
  content: {
    color: colors.primary,
    textTransform: "capitalize",
  },
  inputField: {
    background: colors.tertiary,
    width: "100%",
  },
}));

export default style;
