import * as React from "react";
import { Grid, Typography } from "@mui/material";
import style from "./style";
import Creative from "../../assets/images/svgs/creative.svg";
import Eccentries from "../../assets/images/svgs/eccentries.svg";
import Award from "../../assets/images/svgs/award.svg";

function About() {
  const classes = style();
  return (
    <Grid pl={5} pr={5}>
      <Grid mt={8}>
        <Typography variant="h3" className={classes.title}>
          About US
        </Typography>
        <Grid container mt={10} mb={10}>
          <Grid container item md={4}>
            <Grid item md={3}>
              <img src={Creative} />
            </Grid>
            <Grid item md={9}>
              <Typography variant="h6" className={classes.webApp}>
                We're Creative
              </Typography>
              <Typography
                mt={2}
                pr={8}
                variant="body1"
                className={classes.webContent}
              >
                We will offer you all the creative designs before start any
                website development we will provide you UX designs.
              </Typography>
            </Grid>
          </Grid>
          <Grid container item md={4} mt={15}>
            <Grid item md={3}>
              <img src={Eccentries} />
            </Grid>
            <Grid item md={9}>
              <Typography variant="h6" className={classes.webApp}>
                We're Eccentries
              </Typography>
              <Typography
                mt={2}
                pr={12}
                variant="body1"
                className={classes.webContent}
              >
                We can promise you we will deliver a good Project on time. We
                have a very good team With unlimited skills.
              </Typography>
            </Grid>
          </Grid>
          <Grid container item md={4} mt={30}>
            <Grid item md={3}>
              <img src={Award} />
            </Grid>
            <Grid item md={9}>
              <Typography variant="h6" className={classes.webApp}>
                We're Awarded
              </Typography>
              <Typography
                mt={2}
                pr={8}
                variant="body1"
                className={classes.webContent}
              >
                We will offer you all the creative designs before start any
                website development we will provide you UX designs.
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default About;
