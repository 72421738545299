import makeStyles from "@mui/styles/makeStyles";
import colors from "../../themes/colors";

const style = makeStyles(() => ({
  footerBg: {
    backgroundColor: colors.primary,
  },
  title: {
    color: colors.white,
    textTransform: "uppercase",
  },
  subTitle: {
    color: colors.white,
  },
  logoStyle: {
    height: "50px",
    width: "250px",
  },
}));

export default style;
