import makeStyles from "@mui/styles/makeStyles";
import colors from "../../themes/colors";

const style = makeStyles(() => ({
  headerBg: {
    backgroundColor: colors.black,
    opacity: 0.6,
  },
  title: {
    color: colors.white,
    textTransform: "uppercase",
  },
  home: {
    color: colors.secondary,
    textTransform: "uppercase",
  },
  logoStyle: {
    height: "40px",
    width: "180px",
    margin: "-5px 0 0 0",
  },
}));

export default style;
